<template>
  <div class="ripple-button" ref="btn" @click="buttonClick">{{ btnText }}</div>
</template>

<script>
import { defineComponent, toRef } from "vue";

export default defineComponent({
  name: "ConsultantPreview",
  components: {},

  props: {
    text: {
      required: true,
      type: String,
    },
  },

  setup(props) {
    const btnText = toRef(props, "text").value;
    return {
      btnText,
    };
  },

  methods: {
    buttonClick(e) {
      let x = e.clientX - e.target.offsetLeft;
      let y = e.clientY - e.target.offsetTop;

      let ripples = document.createElement("span");
      ripples.style.left = x + "px";
      ripples.style.top = y + "px";
      ripples.classList.add("ripple-button-span");
      this.$refs.btn.appendChild(ripples);

      setTimeout(() => {
        ripples.remove();
      }, 1000);
      setTimeout(() => {
        this.$emit("clicked");
      }, 100);
    },
  },
});
</script>

<style>
.ripple-button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 35px 35px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;
  border-radius: 5px;
  overflow: hidden;
  background: linear-gradient(90deg, #0162c8, #55e7fc);
  margin: 5px;
  margin-top: 20px;
  text-align: center;
}

.ripple-button-span {
  position: absolute;
  background: #fff;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5s;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
</style>

import Consultant from "@/classes/Consultant";
import MediaEntry from "@/classes/MediaEntry";
import User from "@/classes/User";
export const LocalStorageHelper = {
    methods: {
        InitializeDataStorage() {
            if (!localStorage) {
                console.error("current game does not support LocalStorage");
            } else {
                const user = new User(-1, "", "", "", 0, false, ""); 
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("consultants", "[]");
                localStorage.setItem("mediaFeed", "[]");
            }
        },
       
        GetUser(): User {
            if (!localStorage) {
                console.error("Local storage is not supported by this plattform");
            }

            if (!localStorage.getItem("user")) this.InitializeDataStorage();
            const datastorageJson = localStorage.getItem("user");
            if (datastorageJson != null) {
                const userObj = JSON.parse(datastorageJson); 
                const valid = (userObj.valid == "1") ? true : false;
                return new User(userObj.id, userObj.first_name, userObj.last_name, userObj.profile_picture, userObj.role, valid, userObj.email); 
            } else { 
                console.error("created new user");
                return new User(-1, "", "", "", 0, false, ""); 
            }
        },

        SetUser(user: User) {
            localStorage.setItem("user", JSON.stringify(user));
        },

        GetConsultants(): Array<Consultant> {
            if (!localStorage) {
                console.error("Local storage is not supported by this plattform");
            }

            if (!localStorage.getItem("consultants")) this.InitializeDataStorage();
            const datastorageJson = localStorage.getItem("consultants");
            if (datastorageJson != null) {
                const consultantsArray = JSON.parse(datastorageJson) as Array<Consultant>; 
                const newConsultants: Array<Consultant> = [];
                for(let i = 0; i < consultantsArray.length; i++) {
                    const consultant = consultantsArray[i];
                    newConsultants.push(new Consultant(consultant.id, consultant.first_name, consultant.last_name, consultant.profile_picture, consultant.role, true, consultant.email, consultant.user_id, consultant.consultant_id, consultant.location_latitude, consultant.location_longitude, consultant.experience, consultant.recommendations, consultant.skill_score, consultant.subtitle, consultant.telefone_number, consultant.address_name)); 
                }
                return newConsultants;
            } else { 
                console.error("created new user");
                return []; 
            }
        },


        SetConsultants(consultants: Array<Consultant>) {
            localStorage.setItem("consultants", JSON.stringify(consultants));
        },


        //refactoring
        GetConsultantByMail(email: string): Consultant {
            const consultants = this.GetConsultants();
            const consultantsList = consultants.filter(consultant => consultant.email == email);
            return consultantsList[0];
        },

        //refactoring
        getConsultantById(id: number): Consultant {
            const consultants = this.GetConsultants();
            const consultantsList = consultants.filter(consultant => consultant.id == id);
            return consultantsList[0];
        },

        

        SetMediaFeed(mediaFeed: Array<MediaEntry>) {
            localStorage.setItem("mediaFeed", JSON.stringify(mediaFeed));
        },

        GetMediaFeed(): Array<MediaEntry> {
            if (!localStorage) {
                console.error("Local storage is not supported by this plattform");
            }

            if (!localStorage.getItem("mediaFeed")) this.InitializeDataStorage();
            const datastorageJson = localStorage.getItem("mediaFeed");
            if (datastorageJson != null) {
                const mediaEntryArray = JSON.parse(datastorageJson) as any;
                const newMediaEntries: Array<MediaEntry> = [];
                for(let i = 0; i < mediaEntryArray.length; i++) {
                    newMediaEntries.push(new MediaEntry(mediaEntryArray[i].id, mediaEntryArray[i].created, mediaEntryArray[i].title, mediaEntryArray[i].body_text, mediaEntryArray[i].image_object, mediaEntryArray[i].first_name, mediaEntryArray[i].last_name, mediaEntryArray[i].media_tag)); 
                }
                return newMediaEntries;
            } else { 
                console.error("created new mediafeed");
                return []; 
            }
        }

    }
}

import { defineComponent } from 'vue';
import { MockHelper } from "../helper/MockHelper";

export default defineComponent({
  name: 'Admin',
  components: {

  },

  props: {
       
  },

  setup(props) {
           
      console.log("Admin panel started");
  },

  methods: {
      importUserMocks() {
          MockHelper.methods.addMockUserdata();
          console.log("Importing user mocks");
      },
      importConsultantMetadataMocks() {
          MockHelper.methods.updateConsultantMock();
          console.log("Importing consultant metadata mocks!");
      }
  }
});

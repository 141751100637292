
import Consultant from "@/classes/Consultant";


export const FilterHelper = {
    methods: {
        filterConsultantsByNameCharacters(consultants: Array<Consultant>, filterDigits: string): Array<Consultant> {
            return consultants.filter(consultant => {
                const firstname = consultant.first_name.toLocaleLowerCase();
                const lastname = consultant.last_name.toLocaleLowerCase();
                if (firstname.toLocaleLowerCase().includes(filterDigits.toLocaleLowerCase())) return true;
                if (lastname.toLocaleLowerCase().includes(filterDigits.toLocaleLowerCase())) return true;
                if ((firstname + " " + lastname).includes(filterDigits.toLocaleLowerCase())) return true;
                return false;
            });
        }
    }
}

import { defineComponent } from "vue";
import { reactive, toRefs } from "vue";
export default defineComponent({
  name: "GlowToggleButton",
  components: {},
  props: {
    activated: {
      default: true,
      required: false,
    },
    text: {
      default: "Start",
      required: true,
    },
    disabled: {
      default: false,
      required: false,
    },
  },
  setup() {
    //TODO: not sure if toRefs is the right way
    return toRefs(reactive({ active: false }));
  },
  methods: {
    clickEvent() {
      if (!this.disabled) {
        this.active = this.activated;
        if (!this.active) {
          //Button gets activated
          //TODO: bundle emits in one statement and order is important
          this.$emit("activate");
        } else {
          this.$emit("deactivate");
        }
        this.active = !this.active;
      }
    },
  },
});


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TopNav',
  components: {
  
  },

  props: {
       
  },

  setup() {

      

      console.log("topnav setup");

      return { 

        }
  },

  methods: {
      goBack() {
        this.$router.go(-1);
      },

      activateLink(id: string) {
          const list = document.querySelectorAll('.list');
          list.forEach((item) => {
              item.classList.remove('active');
              
          });
          document.getElementById(id)?.classList.add('active');

      }
  }
});


import { HttpApiService } from "../services/http/http_api_service";
import { defineComponent } from "vue";
import GlowToggleButton from "@/components/ToggleButtons/GlowToggleButton.vue";
export default defineComponent({
  components: {
    GlowToggleButton,
  },

  setup() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      profilePicture: "",
      consultant: false,
    };
  },

  methods: {
    submitRegistrationForm() {
      let role = 1;
      if (this.consultant) role = 2;
      HttpApiService.methods
        .createPlayer(
          this.firstName,
          this.lastName,
          this.email,
          this.password,
          this.profilePicture,
          role
        )
        .then((response) => {
          //user successfully registered
          if (response.status == 200) {
            console.log("sending mail to " + this.email);
            this.$router.push({
              name: "EmailConfirmation",
              params: { userMail: this.email },
            });
          } else {
            console.error("error");
          }
        });
    },
  },
});

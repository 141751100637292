
import { defineComponent } from "vue";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";
import { DataLoader } from "../helper/DataLoader";
import { useRoute } from "vue-router";
import RippleButton from "@/components/RippleButton.vue";

export default defineComponent({
  name: "ConsultantProfile",
  components: {
    RippleButton,
  },

  props: {},

  setup(props) {
    DataLoader.methods.loadConsultants();
    const email = useRoute().params.userMail as string;
    const consultant = LocalStorageHelper.methods.GetConsultantByMail(email);
    console.log("consultant: " + JSON.stringify(consultant));
    return {
      consultant,
    };
  },

  methods: {
    contactConsultant() {
      this.$router.push({
        name: "ContactConsultant",
        params: { receiver_mail: this.consultant.email },
      });
    },
  },
});


import MediaEntry from "@/classes/MediaEntry";
import { defineComponent, toRef, computed } from "vue";

export default defineComponent({
  name: "MediaEntryCard",
  components: {},

  props: {
    mediaEntry: {
      type: MediaEntry,
      required: true,
    },
  },

  setup(props) {
    const mediaEntry = toRef(props, "mediaEntry");

    const entry = computed({
      get: () => {
        return mediaEntry.value;
      },

      set: (value) => {
        value;
      },
    });

    return {
      entry,
    };
  },

  methods: {},
});

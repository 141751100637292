import { HttpApiService } from "../services/http/http_api_service";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";
import Consultant from "@/classes/Consultant";
import MediaEntry from "@/classes/MediaEntry";


export const DataLoader = {
    methods: {


        loadConsultants() {
            let consultants: Array<Consultant> = [];
            HttpApiService.methods.loadConsultants().then((response) => {
                LocalStorageHelper.methods.SetConsultants(response.data as Array<Consultant>);
                consultants = LocalStorageHelper.methods.GetConsultants();
            });
            return consultants;
        },

        loadMediaFeed(): Array<MediaEntry> {
            let mediaFeed: Array<MediaEntry> = [];
            HttpApiService.methods.loadMediaFeed().then((response) => {
                LocalStorageHelper.methods.SetMediaFeed(response.data as Array<MediaEntry>);
                mediaFeed = LocalStorageHelper.methods.GetMediaFeed();
            });
            return mediaFeed;
        },

        updateConsultant(consultant_id: number, location_latitude: number, location_longitude: number, subtitle: string, telefone_number: string, address_name: string) {
            HttpApiService.methods.updateConsultant(consultant_id, location_latitude, location_longitude, subtitle, telefone_number, address_name);
        },

        refreshDataStorage() {
            this.loadConsultants();
            this.loadMediaFeed();
        }
    }
}
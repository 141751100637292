import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a647542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feed-frame" }
const _hoisted_2 = { class: "media-col" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "media-col" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MediaEntryCard = _resolveComponent("MediaEntryCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaFeed, (mediaEntry) => {
        return (_openBlock(), _createElementBlock("div", {
          key: mediaEntry.id
        }, [
          (mediaEntry.media_tag == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_MediaEntryCard, { mediaEntry: mediaEntry }, null, 8, ["mediaEntry"])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaFeed, (mediaEntry) => {
        return (_openBlock(), _createElementBlock("div", {
          key: mediaEntry.id
        }, [
          (mediaEntry.media_tag == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_MediaEntryCard, { mediaEntry: mediaEntry }, null, 8, ["mediaEntry"])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6d2a4c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "search-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConsultantPreviewListItem = _resolveComponent("ConsultantPreviewListItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control search-bar",
        id: "filterTextBox",
        placeholder: "Berater Suchen",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchNameFilter) = $event))
      }, null, 512), [
        [_vModelText, _ctx.searchNameFilter]
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consultantResults, (consultant) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "list-group",
        id: "consultant-list",
        key: consultant.id
      }, [
        _createVNode(_component_ConsultantPreviewListItem, { consultant: consultant }, null, 8, ["consultant"])
      ]))
    }), 128))
  ], 64))
}
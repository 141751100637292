export default class User {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture: string;
    role: number;
    email: string;
    valid: boolean;

    constructor(id: number, first_name: string, last_name: string, profile_picture: string, role: number, valid: boolean, email: string) {
        this.id = id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.profile_picture = profile_picture;
        this.role = role;
        this.valid = valid;
        this.email = email;
    }

    setValid() {
        this.valid = true;
    }
}
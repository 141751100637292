export default class MediaEntry {
	id: number;
	created: string;
	title: string;
	body_text: string;
	image_object: any;
	first_name: string;
	last_name: string;
	media_tag: number;


	constructor(id: number, created: string, title: string, body_text: string, image_object: any, first_name: string, last_name: string, media_tag: number) {
		this.id = id;
		this.created = created;
		this.title = title;
		this.body_text = body_text;
		this.image_object = image_object;
		this.first_name = first_name;
		this.last_name = last_name;
		this.media_tag = media_tag;
	}
}
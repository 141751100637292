import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      type: "submit",
      class: "btn btn-primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.importUserMocks()))
    }, "User Mocks importieren"),
    _createElementVNode("button", {
      type: "submit",
      class: "btn btn-primary",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.importConsultantMetadataMocks()))
    }, "Consultant Metadata Mocks importieren")
  ], 64))
}
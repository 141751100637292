import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", null, [
      _createTextVNode(_toDisplayString(_ctx.statusText) + " ", 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (this.SendEmailConfirmation()))
      }, "Send Confirmation")
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
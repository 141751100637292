import { HttpApiService } from "@/services/http/http_api_service";
import userData from "../mockdata/user_mock.json";
import consultantData from "../mockdata/consultants_mock.json";
import { LocalStorageHelper } from "./LocalStorageHelper";


export const MockHelper = {
    methods: {
        addMockUserdata() {
            (userData as Array<any>).forEach(element => {            
                HttpApiService.methods.createPlayer(element.first_name, element.last_name, element.email, element.password, element.profile_picture, element.role).then((response) => {
                    if (response.status == 200) {
                        console.log("creating user " + element.first_name + " " + element.last_name); 
                    } else {
                        console.error("error");
                    }
                });

            });
        },

        updateConsultantMock() {
            const consultantsMetadata: any = (consultantData as Array<any>);
            const consultants = LocalStorageHelper.methods.GetConsultants();
            if (consultants.length > consultantsMetadata.length) {
                console.error("You need the same amount or more of metadata than consultants: Consultants: " + consultants.length + " Metadata: " + consultantsMetadata.length);
                return;
            }
                for(let i = 0; i < consultants.length; i++) {
                    const consultantMetadata = consultantsMetadata[i];
                    HttpApiService.methods.updateConsultant(consultants[i].id, consultantMetadata.location_latitude, consultantMetadata.location_longitude, consultantMetadata.subtitle, consultantMetadata.telefone_number, consultantMetadata.address_name) .then((response) => {
                        
                        if (response.status == 200) {
                            console.log("updating metadata for consultant  " + consultants[i].first_name + " " + consultants[i].last_name); 
                        } else {
                            console.error("error");
                        }
                        
                    });
                }
                
            }
        }
        
    
}
<template>
  <form id="login-form" v-if="!this.loginFailed.value">
    <img src="../assets/logo.png" id="logo" />
    <div class="form-group">
      <label>Email</label>
      <input
        type="email"
        class="form-control"
        id="email"
        aria-describedby="email"
        placeholder="Enter email"
        v-model="email"
      />
    </div>
    <div class="form-group">
      <label>Password</label>
      <input
        type="password"
        class="form-control"
        id="password"
        placeholder="Password"
        v-model="password"
      />
    </div>
    <button
      type="submit"
      id="login-button"
      class="btn btn-primary"
      @click="login"
    >
      Login
    </button>
    <div id="registerinfo">
      Du hast noch kein Konto? <a href="Register">Registrieren</a><br />
      <a href="/forgotpw">Passwort vergessen?</a>
      <a href="./assets/AGB.pdf">Datenschutzbestimmungen</a>
    </div>
  </form>
  <div id="loginErrorMessage" v-if="this.loginFailed.value">
    {{ this.loginMessage }}
  </div>

  <router-view />
</template>
<script>
import { HttpApiService } from "../services/http/http_api_service";
import { defineComponent, reactive } from "vue";
import User from "../classes/User";
import { useStore } from "vuex";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";

import { inject } from "vue";

export default defineComponent({
  inject: ["Auth"],
  components: {},
  setup() {
    const store = useStore();
    const loginFailed = reactive({ value: false });
    const loginMessage = "";
    const auth = inject("Auth");

    return {
      ...auth,
      loginMessage,
      loginFailed,
      email: "",
      password: "",
      store,
    };
  },

  methods: {
    login() {
      this.Auth.loginWithRedirect();
    },

    submitLoginForm() {
      let user; //: User;
      HttpApiService.methods
        .authenticateUser(this.email, this.password)
        .then((response) => {
          user = response.data; // as User;

          if (user.id == null) {
            this.loginFailed.value = true;
            this.loginMessage =
              "Authentifizierung fehlgeschlagen. Bitte versuchen sie es erneut!";
            console.log("authentifizierung fehlgeschlagen");
          } else {
            this.loginFailed.value = false;
            console.log("authentifizierung erfolgreich");
            LocalStorageHelper.methods.SetUser(user);
            if (LocalStorageHelper.methods.GetUser().valid) {
              //account is confirmed
              console.log("Redirecting to Homescreen");
              this.$router.push("HomeScreen");
            } else {
              //account is not confirmed
              this.$router.push({
                name: "EmailConfirmation",
                params: { userMail: this.email },
              });
            }
          }
        });
    },
  },
});
</script>

<style scoped>
.form-group {
  margin: 50px;
}

#loginErrorMessage {
  color: red;
  margin: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid black;
  background-color: white;
}

#logo {
  width: 300px;
  height: 300px;
  position: relative;
  margin: 0 auto;
  display: blocK;
}

#navigation {
  display: none;
}

#login-form {
  max-width: 500px;
  margin: 0 auto;
}

#login-button {
  display: block;
  margin: 0 auto;
  width: 80%;
}

#registerinfo {
  margin: 0 auto;
  text-align: center;
}
</style>
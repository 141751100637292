import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue';
import HomeScreen from '../views/HomeScreen.vue';
import ConsultantsOverview from '../views/ConsultantsOverview.vue';
import ConsultantProfile from '../views/ConsultantProfile.vue';
import Admin from '../views/Admin.vue';
import MediaFeed from '../views/MediaFeed.vue';
import ContactConsultant from '../views/ContactConsultant.vue';
import ConsultantMap from '../views/ConsultantMap.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/EmailConfirmation',
    name: 'EmailConfirmation',
    component: EmailConfirmation,
    props: { userMail: ""}
  },
  {
    path: '/Contact',
    name: 'ContactConsultant',
    component: ContactConsultant,
    props: { receiver_mail: "" }
  },
  {
    path: '/HomeScreen',
    name: 'HomeScreen',
    component: HomeScreen
  },
  {
    path: '/Consultants',
    name: 'Consultants',
    component: ConsultantsOverview
  },
  {
    path: '/ConsultantProfile',
    name: 'ConsultantProfile',
    component: ConsultantProfile,
    props: { userMail: ""}
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/MediaFeed',
    name: 'MediaFeed',
    component: MediaFeed
  },
  {
    path: '/Map',
    name: 'Map',
    component: ConsultantMap
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("userToken") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!store.state.isAuthenticated) {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
*/
export default router

<template>
  <div id="navigation">
    <div id="top-nav-spacer"/>
    <div id="top-nav">
      <TopNav></TopNav>
    </div>
  </div>
  <div id="nav">

    
  </div>
  <div id="mainframe" class="text-white">
    <router-view/>
  </div>
</template>

<script>
import TopNav from "./components/TopNav.vue";
export default {
  name: 'App',
  components: {
    TopNav
  },
}
</script>

<style>
#nav {
  text-align: center;
  height: 50px;
}

#top-nav {
  width: 100%;

  background-color: #24376f;
  position: fixed;
  top: 0px;
  z-index: 10;
}

#top-nav-spacer {
  height: 70px;
}
</style>

export default class Consultant {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture: string;
    role: number;
    email: string;
    valid: boolean;
    consultant_id: number;
    location_latitude: number;
    location_longitude: number;
    experience: number;
    recommendations: number;
    skill_score: number;
    user_id: number;
    subtitle: string;
    telefone_number: string;
    address_name: string;

    constructor(id: number, first_name: string, last_name: string, profile_picture: string, role: number, valid: boolean, email: string, user_id: number, consultant_id: number, location_latitude: number, location_longitude: number, experience: number, recommendations: number, skill_score: number, subtitle: string, telefone_number: string, address_name: string) {
        this.id = id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.profile_picture = profile_picture;
        this.role = role;
        this.valid = valid;
        this.email = email;
        this.user_id = user_id
        this.consultant_id = consultant_id;
        this.location_latitude = location_latitude;
        this.location_longitude = location_longitude;
        this.experience = experience;
        this.recommendations = recommendations;
        this.skill_score = skill_score;
        this.subtitle = subtitle;
        this.telefone_number = telefone_number;
        this.address_name = address_name;
    }

    /*
        this.id = id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.profile_picture = profile_picture;
        this.role = role;
        this.valid = valid;
        this.email = email;
    */
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61ae071c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { id: "welcome_card" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RippleButton = _resolveComponent("RippleButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user.first_name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, "Nicht eingeloggt"))
    ]),
    _createVNode(_component_RippleButton, {
      onClicked: _ctx.routeConsultants,
      text: "Beratersuche"
    }, null, 8, ["onClicked"]),
    _createVNode(_component_RippleButton, {
      onClicked: _ctx.routeLogin,
      text: "Login"
    }, null, 8, ["onClicked"]),
    _createVNode(_component_RippleButton, {
      onClicked: _ctx.routeMediaFeed,
      text: "Bibliothek"
    }, null, 8, ["onClicked"]),
    _createVNode(_component_RippleButton, {
      onClicked: _ctx.routeMap,
      text: "Karte"
    }, null, 8, ["onClicked"])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-378cf19b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "media-entry" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.entry.title), 1),
      _createElementVNode("iframe", {
        src: _ctx.entry.body_text,
        class: "video-iframe"
      }, null, 8, _hoisted_2),
      _createElementVNode("img", {
        class: "media-entry-image",
        src: _ctx.entry.image_object
      }, null, 8, _hoisted_3)
    ])
  ]))
}
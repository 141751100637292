
import { defineComponent } from "vue";
import { HttpApiService } from "../services/http/http_api_service";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";
import { useRoute } from "vue-router";

export default defineComponent({

  setup() {
    let statusText = "sd";
  const email = useRoute().params.userMail as string;
    return {
      statusText,
      email
    }
  },

  methods: {
    SendEmailConfirmation() {
      //const user = LocalStorageHelper.methods.GetUser();

      
      console.log("email: " + this.email);
      HttpApiService.methods.sendConfirmationMail(this.email).then((response) => {
        if (response.status == 200) {
          this.statusText = `
            willkommen bei Finpact!

            Bitte verifiziere deine E-Mail Addresse mit diesem Link:

            <button type="button" style="box-shadow: 0 1px 4px rgba(0, 0, 0, .6); color: #ecf0f1; transition: background-color .3s;"/>

            ...und erhalte Zugriff auf geprüftes Finanzwissen und persönliche Beratung.
          `;
          //this.statusText = "Mail wurde versand";
        } else {
          this.statusText = "Fehler beim mailversand";
        }
      });
    }
  },

  
});


import { defineComponent, computed, ref, watch } from 'vue';
import { DataLoader } from "../helper/DataLoader";
import { FilterHelper } from "../helper/FilterHelper";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";
import ConsultantPreviewListItem from "../components/consultants/ConsultantPreviewListItem.vue";

export default defineComponent({
  name: 'ConsultantsOverview',
  components: {
      ConsultantPreviewListItem
  },

  setup() {

      DataLoader.methods.refreshDataStorage();

    
      const consultants = ref(LocalStorageHelper.methods.GetConsultants());
      const consultantResults = computed({
        get: () => {
          return FilterHelper.methods.filterConsultantsByNameCharacters(consultants.value, searchNameFilter.value);
        },
        
        set: (value) => {
          value
        }
      });

      const searchNameFilter = ref("");
      watch(searchNameFilter, () => {
        
        consultantResults;
      });

      return {
          consultantResults,
          searchNameFilter
      }
  },

  methods: {
     
  },


});


import { HttpApiService } from "../services/http/http_api_service";
import { defineComponent } from "vue";
import User from "../classes/User";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";
import { useRoute } from "vue-router";


export default defineComponent({
  props: {

  },

  setup(props) {
    const user = LocalStorageHelper.methods.GetUser();
    const receiver: string = useRoute().params.receiver_mail as string;
    return {
      user,
      telNumber: '',
      body: '',
      receiver
    }
  },

  methods: {
    submitMessageForm() {
      console.log("contactinfg " + this.receiver);
        HttpApiService.methods.sendMessage(this.receiver, this.body, this.user.email, this.user.first_name + " " + this.user.last_name);
    }
  }
});


import MediaEntry from "@/classes/MediaEntry";
import { LocalStorageHelper } from "@/helper/LocalStorageHelper";
import { defineComponent, reactive } from "vue";
import { DataLoader } from "../helper/DataLoader";
import MediaEntryCard from "@/components/mediafeed/MediaEntryCard.vue";

export default defineComponent({
  components: {
    MediaEntryCard,
  },

  setup() {
    DataLoader.methods.refreshDataStorage();
    const mediaFeed: Array<MediaEntry> = reactive(
      LocalStorageHelper.methods.GetMediaFeed()
    );

    return {
      mediaFeed,
    };
  },
  methods: {},
});

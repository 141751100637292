import User from '@/classes/User'
import { createStore } from 'vuex'

export default createStore({
  state: {
    user: User,
    loggedIn: false
  },
  mutations: {
    SET_USER: (state, data) => {
      state.user = data;
      state.loggedIn = true;
    },
  },
  actions: {
  },
  modules: {
  }
})

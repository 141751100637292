<template>
  <div
    v-show="loading.value"
    class="spinner-border spinner-border-sm loading-indicator"
    role="status"
  ></div>
  <div id="popup" class="ol-popup nodisplay">
    <a @click="closePopup" id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content"></div>
    <RippleButton @click="divRouteToConsultant" text="Ansehen"></RippleButton>
  </div>
  <div
    ref="map-root"
    id="map-frame"
    style="width: 1000px; height: 1000px"
  ></div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import point from "ol/geom/Point";
import Style from "ol/style/Style";
import { fromLonLat } from "ol/proj";
import { LocalStorageHelper } from "../../helper/LocalStorageHelper";
import { DataLoader } from "../../helper/DataLoader";
import { Geolocation } from "@capacitor/geolocation";
import Overlay from "ol/Overlay";
import "ol/ol.css";
import Icon from "ol/style/Icon";
import RippleButton from "@/components/RippleButton.vue";
//import ConsultantPreviewCard from "@/components/consultants/ConsultantPreviewCard.vue";

export default defineComponent({
  name: "MapContainer",
  components: {
    RippleButton,
  },

  setup() {
    const data = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
      },
    };

    const ownLat = 0.0;
    const ownLon = 0.0;

    const loading = reactive({ value: true });

    return {
      data,
      map: Map,
      ownLat,
      ownLon,
      loading,
    };
  },
  methods: {
    closePopup() {
      document.getElementById("popup").style.display = "none";
    },
    async getLocation() {
      const coordinates = await Geolocation.getCurrentPosition();
      this.ownLat = coordinates.coords.latitude;
      this.ownLon = coordinates.coords.longitude;
      return coordinates;
    },

    resizeMap() {
      const width = document.getElementById("mainframe").offsetWidth;
      console.log("resizing to " + width);
      document.getElementById("map-frame").style.width =
        width.toString() + "px";
    },
    async initializeMap() {
      const mainframe = document.getElementById("mainframe");
      document.getElementById("map-frame").width = mainframe.width;
      document.getElementById("map-frame").height = mainframe.height;

      try {
        this.resizeMap();
        const location = await this.getLocation();
        this.drawMap(location);
        this.loadConsultantMarkers();
        this.loading.value = false;
      } catch (e) {
        this.errorStr = e.message;
        this.loading.value = false;
      }
    },

    drawMap(location) {
      let map = new Map({
        target: this.$refs["map-root"],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],

        view: new View({
          zoom: 0,
          center: [this.ownLat, this.ownLon],
          constrainResolution: false,
        }),
      });

      this.map = map;
    },

    makeMarker(lat, lng, index) {
      let marker = new Feature({
        geometry: new point(fromLonLat([lng, lat])),
        id: index,
      });

      //const markerImage = ;
      if (index == 1) {
        const markerStyle = new Style({
          image: new Icon({
            src: require("../../assets/marker.png"),
          }),
        });
        marker.setStyle(markerStyle);
      }
      return marker;
    },

    addMarkerFeatures(markers) {
      let vectorSource = new VectorSource({ features: markers });
      var markerVectorLayer = new VectorLayer({
        source: vectorSource,
      });
      if (!this.map) {
        console.error("map not intitialized");
        return;
      }
      console.log(this.map);
      this.map.addLayer(markerVectorLayer);

      const element = document.getElementById("popup");
      if (!element) {
        console.error("popup element not found");
        return;
      }

      const popup = new Overlay({
        element: element,
        positioning: "bottom-center",
        stopEvent: false,
      });
      this.map.addOverlay(popup);

      this.map.on("click", function (evt) {
        var feature = this.forEachFeatureAtPixel(evt.pixel, function (feature) {
          return feature;
        });
        if (feature) {
          console.log(evt.coordinate);
          popup.setPosition(evt.coordinate);
          const content = document.getElementById("popup-content");
          content.innerHTML = "";
          if (!content) return;
          const popupHtml = document.createElement("div");
          //popupHtml += "<a href='Home'>heim</a>";
          const id = feature.getProperties().id;
          const consultant = LocalStorageHelper.methods.getConsultantById(id);

          const img = document.createElement("img");
          img.src = consultant.profile_picture;
          img.classList.add("preview-img");
          img.style.width = "10em";
          img.style.height = "10em";

          popupHtml.appendChild(img);

          const text = document.createElement("a");
          text.innerHTML = consultant.first_name;
          text.innerHTML += "<br>";
          text.innerHTML += consultant.last_name;
          popupHtml.appendChild(text);

          const idHtml = document.createElement("a");
          idHtml.innerHTML = consultant.id;
          idHtml.setAttribute("id", "consultantId");
          idHtml.style.display = "none";
          popupHtml.appendChild(idHtml);
          //popupHtml += "<Button onclick='#'>Kontaktieren</Button>";

          content.appendChild(popupHtml);
          //let newpopup = document.createElement("div");

          //content.appendChild(newpopup);
          document.getElementById("popup").style.display = "initial";
          console.log(consultant);
        }
      });
    },

    loadConsultantMarkers() {
      console.log("drin");
      DataLoader.methods.refreshDataStorage();
      const consultants = LocalStorageHelper.methods.GetConsultants();
      let markers = [];
      for (var i = 0; i < consultants.length; i++) {
        let marker = this.makeMarker(
          consultants[i].location_longitude,
          consultants[i].location_latitude,
          consultants[i].id
        );
        markers.push(marker);
      }
      markers.push(this.makeMarker(this.ownLat, this.ownLon, 1));
      this.addMarkerFeatures(markers);
      //consultant.location_longitude;
    },

    //very bad dom information gathering, but osm marker onclick scope is driving me crazy and time is running out!!!
    divRouteToConsultant(sender) {
      let id = document.getElementById("consultantId").innerHTML;
      id = parseInt(id);
      this.routeToConsultant(id);
    },

    routeToConsultant(id) {
      //DataLoader.methods.refreshDataStorage();
      const consultants = ref(LocalStorageHelper.methods.GetConsultants());
      const subConsultants = consultants.value.filter(
        (consultant) => consultant.id == id
      );
      const consultant = subConsultants[0];
      this.$router.push({
        name: "ConsultantProfile",
        params: { userMail: consultant.email },
      });
    },
    test() {
      alert("hi");
    },
  },

  mounted() {
    this.initializeMap();
  },
});
</script>

<style scoped>
#map-frame {
  z-index: 0;
}

.ol-popup img {
  margin: 0 auto;
  width: 50px;
  height: 50px;
}
.ol-popup {
  color: black;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.loading-indicator {
  position: fixed;
  width: 500px;
  height: 500px;

  z-index: 9;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.preview-img {
  width: 50px;
  height: 50px;
}

.nodisplay {
  display: none;
}
</style>

import Consultant from "@/classes/Consultant";
import MediaEntry from "@/classes/MediaEntry";
import axios from "axios";
import { stringifyQuery } from "vue-router";

import User from "../../classes/User";
export const HttpApiService = {
    methods: {
        createPlayer(firstName: string, lastName: string, email: string, password: string, profilePicture: string, role: number) {
            return axios.post<User>('https://df.brand-soft.de/create_user.php', {
                first_name: firstName,
                last_name: lastName,
                email: email,
                username: firstName + lastName,
                password: password,
                profile_picture: profilePicture,
                role: role
            });
        },

        authenticateUser(email: string, password: string) {
            return axios.post<User>('https://df.brand-soft.de/authenticate.php', {
                email: email,
                password: password
            });
        },

        sendConfirmationMail(email: string) {
            return axios.post('https://df.brand-soft.de/send_confirmation_mail.php', {
                email: email
            });
        },

        sendMessage(mail_receiver: string, message: string, sender_mail: string, sender_name: string) {
            return axios.post('https://df.brand-soft.de/send_message.php', {
                receiver: mail_receiver,
                message: message,
                sender_mail: sender_mail,
                sender_name: sender_name
            });
        },

        updateConsultant(consultant_id: number, location_latitude: number, location_longitude: number, subtitle: string, telefone_number: string, address_name: string) {
            return axios.post('https://df.brand-soft.de/update_consultant.php', {
                consultant_id: consultant_id,
                location_latitude: location_latitude,
                location_longitude: location_longitude,
                subtitle: subtitle,
                telefone_number: telefone_number,
                address_name: address_name
            });
        },

        loadConsultant(user: User) {
            return axios.post('https://df.brand-soft.de/load_consultant.php', {
                user: user
            });
        },

        loadConsultants() {
            return axios.get<Array<Consultant>>('https://df.brand-soft.de/load_consultants.php', {

            });
        },

        loadMediaFeed() {
            return axios.get<Array<MediaEntry>>('https://df.brand-soft.de/load_media_feed.php', {

            });
        }

    }

}
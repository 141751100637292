
import { defineComponent } from "vue";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";

export default defineComponent({
  setup() {
    const user = LocalStorageHelper.methods.GetUser();
    return {
      user
    }
  },
  methods: {
      
  }
});


import { defineComponent, toRef } from 'vue';
import Consultant from '@/classes/Consultant';


export default defineComponent({
  name: 'ConsultantPreviewCard',
  components: {

  },

  props: {
        consultant: {
            required: true,
            type: Consultant
        } 
  },

  setup(props) {
      const consultantUser = toRef(props, "consultant");
      
      return {
          consultantUser
      }
  },

  methods: {
      routeToConsultant() {
        this.$router.push({ name: 'ConsultantProfile', params: { userMail: this.consultant.email } })
      }
  }
});


import { defineComponent } from "vue";
import { LocalStorageHelper } from "../helper/LocalStorageHelper";
import User from "@/classes/User";
import RippleButton from "@/components/RippleButton.vue";

export default defineComponent({
  name: "Home",
  components: {
    RippleButton,
  },

  setup() {
    const user = LocalStorageHelper.methods.GetUser() as User;

    return {
      user,
    };
  },

  methods: {
    routeMap() {
      //document.getElementById("navMap").click();
      this.$router.push({ name: "Map" });
    },
    routeLogin() {
      document.getElementById("navRegister").click();
      this.$router.push({ name: "Login" });
    },
    routeMediaFeed() {
      document.getElementById("navLibrary").click();
      this.$router.push({ name: "MediaFeed" });
    },
    routeConsultants() {
      document.getElementById("navSearch").click();
      this.$router.push({ name: "Consultants" });
    },
  },
});


import { defineComponent } from 'vue';
import MapContainer from "../components/map/MapContainer.vue"


export default defineComponent({
  name: 'ConsultantMap',
  components: {
    MapContainer
  },

  setup() {
     console.log("hi");
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d29be636"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "list-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routeToConsultant && _ctx.routeToConsultant(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.consultant.first_name) + " " + _toDisplayString(_ctx.consultant.last_name), 1)
  ]))
}
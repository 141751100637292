import { createApp, VueElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Auth0} from "@/auth";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./css/main.css";

async function init() {
    const AuthPlugin = await Auth0.init({
        onRedirectCallback: (appState) => {
            router.push(
                appState && appState.targetUrl
                    ? appState.targetUrl
                    : window.location.pathname,
            )
        },
        clientId: "AbZ9nfYngtWuxD74mh72A7SVPNbruon0",
        domain: "dev-jce73jfj.us.auth0.com",
        audience: "",//process.env.VUE_APP_AUTH0_AUDIENCE,
        redirectUri: window.location.origin,
    });


    const app = createApp(App);
    app.config.globalProperties.$store = store;
    app.use(store);
    app.use(router);
    app.use(AuthPlugin);
    app.mount('#app');
}

init();

